<template>
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            size="lg"
            @hide="resetForm()"
            @shown="initData()"
    >
        <template #modal-title>
            <template v-if="isAnnex">{{$t('label_add_annex')}}</template>
            <template v-else>{{action == 'adding' ? $t('label_add_agreement') : $t('label_edit')}}</template>
        </template>

        <template #default="{ hide }">
            <!-- Form -->
            <validation-observer
                    #default="{ handleSubmit, invalid }"
                    :ref="PREFIX + '_FORM'"
                    tag="form"
                    class="p-1"
            >



                <validation-provider
                        v-if="isAnnex"
                        #default="validationProps"
                        :name="$t('label_annex_effective_date_from')"
                        rules="required|date-gt-current:DD/MM/YYYY"
                        class="mr-1 flex-grow-1"
                        slim
                >


                    <b-form-group
                            :label="$t('label_annex_effective_date_from')"
                    >

                        <custom-date-picker
                                :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                :locale="$store.state.appConfig.locale"
                                :placeholder="$t('label_DD-MM-YYYY')"
                                :value="itemData.apply_at"

                                @input="itemData.apply_at = $event"
                        >
                            <template #clear-btn="{ vm }">
                                <feather-icon icon="XIcon" size="14"/>
                            </template>
                            <template #label>
                                <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                            </template>
                        </custom-date-picker>

                        <!--@on-change="(val) => {$emit('update:dateFromFilter', (val && val[0])?$moment(val[0]).format('DD-MM-Y'):null);}"-->

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider

                        #default="validationProps"
                        :name="$t('label_deal_number')"
                        rules="required"
                        slim
                >
                    <b-form-group :label="$t('label_category_list')"
                    >
                        <!--<infinity-scroll-->
                                <!--v-model="itemData.id_contract_category"-->
                                <!--selected-prop="id"-->
                                <!--:url="'/' + MODULE_PREFIX + '_' + CATEGORY_PREFIX"-->
                                <!--:placeholder="$t('label_select')"-->
                                <!--:default-selection="chosenCategory"-->
                        <!--&gt;-->

                            <!--<template #label="{item}">{{item.name}}</template>-->

                        <!--</infinity-scroll>-->
                        <v-select
                                v-model="itemData.id_contract_category"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="contractCategories"
                                :reduce="val => val.id"
                                label="idCountry"
                                :clearable="false"
                                :placeholder="$t('label_select')"
                        >
                            <template v-slot:option="option">
                                {{option.name}}
                            </template>
                            <template v-slot:selected-option="option">
                                {{option.name}}
                            </template>
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>


                <b-form-group
                        class="mt-2"
                        label=""
                        label-for="without_end_date"
                >
                    <b-form-checkbox
                            id="without_end_date"
                            value="1"
                            unchecked-value="0"
                            switch
                            size="md"
                            v-model="itemData.without_end_date"
                    >
                        <label for="fill_data">{{ $t('label_endless_agreement') }}</label>
                    </b-form-checkbox>
                </b-form-group>

                <b-row>
                    <b-col cols="12" md="6">
                <validation-provider
                        #default="validationProps"
                        :name="$t('label_date_agreement_conclusion')"
                        rules="required"
                        class="flex-grow-1"
                        slim
                        vid="contract_date"
                >
                    <b-form-group
                            :label="$t('label_date_agreement_conclusion')"
                    >

                        <custom-date-picker
                                :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                :locale="$store.state.appConfig.locale"
                                :placeholder="$t('label_DD-MM-YYYY')"

                                :value="itemData.contract_date"
                                @input="itemData.contract_date = $event"
                        >
                            <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                            <div slot="label">
                                <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                            </div>
                        </custom-date-picker>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                    </b-col>
                    <b-col cols="12" md="6">
                <validation-provider v-if="itemData.without_end_date == 0"
                        #default="validationProps"
                        :name="$t('label_agreement_expiration_date')"
                        rules="required|date-gt-than:@contract_date,'DD/MM/YYYY'"
                        class="flex-grow-1"
                        slim
                >
                    <b-form-group
                            :label="$t('label_agreement_expiration_date')"
                    >

                        <custom-date-picker
                                :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                :locale="$store.state.appConfig.locale"
                                :placeholder="$t('label_DD-MM-YYYY')"

                                :value="itemData.contract_end_date"
                                @input="itemData.contract_end_date = $event"
                        >
                            <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                            <div slot="label">
                                <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                            </div>
                        </custom-date-picker>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" md="6">
                <validation-provider
                        #default="validationProps"
                        :name="$t('label_valid_from')"
                        rules="required"
                        class="flex-grow-1"
                        slim
                        vid="contract_validity_date"
                >
                    <b-form-group
                            :label="$t('label_valid_from')"
                    >

                        <custom-date-picker
                                :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                :locale="$store.state.appConfig.locale"
                                :placeholder="$t('label_DD-MM-YYYY')"

                                :value="itemData.contract_validity_date"
                                @input="itemData.contract_validity_date = $event"
                        >
                            <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                            <div slot="label">
                                <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                            </div>
                        </custom-date-picker>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                    </b-col>
                    <b-col cols="12" md="6">
                <validation-provider v-if="itemData.without_end_date == 0"
                        #default="validationProps"
                        :name="$t('label_valid_until')"
                        rules="required|date-gt-than:@contract_validity_date,'DD/MM/YYYY'"
                        class="flex-grow-1"
                        slim
                >
                    <b-form-group
                            :label="$t('label_valid_until')"
                    >

                        <custom-date-picker
                                :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                :locale="$store.state.appConfig.locale"
                                :placeholder="$t('label_DD-MM-YYYY')"

                                :value="itemData.contract_end_validity_date"
                                @input="itemData.contract_end_validity_date = $event"
                        >
                            <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                            <div slot="label">
                                <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                            </div>
                        </custom-date-picker>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                    </b-col>
                </b-row>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_agreement_subject')"
                        rules="required"
                        slim
                        mode="aggressive"
                >
                    <b-form-group
                            :label="$t('label_agreement_subject')"
                    >
                        <b-form-input
                                v-model="itemData.contract_subject"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_agreement_subject')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_supplier_executor_contractor')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_supplier_executor_contractor')"
                    >
                        <b-form-input
                                v-model="itemData.attendant_user"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_supplier_executor_contractor')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_description')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_description')"
                            label-for="meeting_type_id"
                    >
                        <b-form-textarea
                                rows="5"
                                v-model="itemData.contract_description"
                        >

                        </b-form-textarea>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <b-form-group
                        class="mt-2"
                        label=""
                        label-for="fill_data"
                >
                    <b-form-checkbox
                            id="fill_data"
                            value="1"
                            unchecked-value="0"
                            switch
                            size="md"
                            v-model="show_additional_data"
                    >
                        <label for="fill_data">{{ $t('label_fill_data') }}</label>
                    </b-form-checkbox>
                </b-form-group>

                <div v-show="show_additional_data == 1">
                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_country')"
                            rules=""
                            slim
                    >
                        <b-form-group :label="$t('label_country')"
                        >

                            <v-select
                                    v-model="itemData.contract_id_country"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="countries"
                                    :reduce="val => val.idCountry"
                                    label="idCountry"
                                    :clearable="false"
                                    :placeholder="$t('label_select')"
                            >
                                <template v-slot:option="option">
                                    {{option.countryName}}
                                </template>
                                <template v-slot:selected-option="option">
                                    {{option.countryName}}
                                </template>
                                <template v-slot:no-options="option">
                                    {{$t('label_no_items')}}
                                </template>
                            </v-select>

                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>

                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_town')"
                            rules=""
                            slim
                            mode="aggressive"
                    >
                        <b-form-group
                                :label="$t('label_town')"
                        >
                            <b-form-input
                                    v-model="itemData.contract_city"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_town')"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_street_and_house_number')"
                            rules=""
                            slim
                            mode="aggressive"
                    >
                        <b-form-group
                                :label="$t('label_street_and_house_number')"
                        >
                            <b-form-input
                                    v-model="itemData.contract_street"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_street_and_house_number')"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_post_code')"
                            rules=""
                            slim
                            mode="aggressive"
                    >
                        <b-form-group
                                :label="$t('label_post_code')"
                        >
                            <b-form-input
                                    v-model="itemData.contract_zip"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_post_code')"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_nip')"
                            rules=""
                            slim
                            mode="aggressive"
                    >
                        <b-form-group
                                :label="$t('label_nip')"
                        >
                            <b-form-input
                                    v-model="itemData.contract_nip"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_nip')"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_krs')"
                            rules=""
                            slim
                            mode="aggressive"
                    >
                        <b-form-group
                                :label="$t('label_krs')"
                        >
                            <b-form-input
                                    v-model="itemData.contract_krs"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_krs')"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </div>


                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_to_ocr')"
                        slim
                >
                    <b-form-group
                            label=""
                            label-for="meeting_type_id"
                    >
                        <b-form-checkbox
                                id="c-reg-ocr"
                                value="1"
                                unchecked-value="0"
                                v-model="itemData.ocr"
                        >
                            <label for="c-reg-ocr">{{ $t('label_to_ocr') }}</label>
                        </b-form-checkbox>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        :name="$t('label_document')"
                        slim
                        #default="validationProps"

                >

                    <file-input

                            v-model="documents"
                            class="file--uploader drop-block mb-3"
                            :max-files-count='10'
                            :required="true"
                            :multiple="true"
                            allowed-extensions="*"
                            :max-file-size='15'
                            :url="''"
                    >
                        <template #uploadBody>
                            <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                            <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                            {{$t('label_add_file')}}
                        </template>

                        <template #errorBug="fileErrors">

                            <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>
                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </template>
                    </file-input>

                </validation-provider>

                <div class="d-flex justify-content-end mt-2">

                    <button class=" btn btn-default" @click.prevent="$bvModal.hide(PREFIX + '-modal')">
                        {{$t('label_cancel')}}
                    </button>

                    <button type="submit" @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                            class="btn btn-primary waves-effect waves-light">{{(action == 'editing')?$t('label_save'):$t('label_add')}}
                    </button>
                </div>

            </validation-observer>


        </template>
    </b-modal>
</template>

<script>
    import
    {ValidationProvider, ValidationObserver} from 'vee-validate'

    import {CONTRACT_REG_PREFIX as MODULE_PREFIX, CONTRACT_REG_PREFIX as PREFIX, CATEGORY_PREFIX} from './../moduleHelper'
    import {
        BFormTextarea,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BInputGroupPrepend,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BBadge
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import  fileInput from '@/views/components/fileUploadInput'
    import  infinityScroll from '@/views/components/infinityScroll'

    import {serialize} from 'object-to-formdata';

    export default {
        components: {

            BForm,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupPrepend,
            BInputGroupAppend,
            BFormCheckbox,
            BBadge,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            fileInput,
            infinityScroll,

        },
        props:['editedItem', 'isAnnex'],
        data() {
            return {

                MODULE_PREFIX,
                PREFIX,
                CATEGORY_PREFIX,

                checkboxes: [],

                action: 'adding',

                blankItemData: {
                    id_contract: 0,
                    id_contract_category: null,
                    contract_date: '',
                    contract_end_date: '',
                    contract_validity_date: '',
                    contract_end_validity_date: '',
                    contract_subject: '',
                    contract_description: '',
                    // contract_firstname: '',
                    // contract_lastname: '',
                    contract_id_country: null,
                    contract_city: '',
                    contract_street: '',
                    contract_zip: '',
                    contract_nip: '',
                    contract_krs: '',
                    // termination_at: '',
                    attendant_user:'',
                    ocr: 0,
                    without_end_date: 0,
                    apply_at: null
                },

                itemData: {},
                documents:[],
                countries: [],
                contractCategories: [],
                show_additional_data: 0,

            }
        },

        methods: {
            initData() {

                if (this.editedItem) {
                    this.action = 'editing';
                    let item = Object.assign({}, this.blankItemData);
                    item.id_contract = this.editedItem.id_contract;

                    for (let prop in item) {
                        if (this.editedItem.hasOwnProperty(prop)) {
                            item[prop] = this.editedItem[prop];
                        }
                    }

                    // item.termination_at = formatDate(this.editedItem.termination_at, 'DD/MM/YYYY', 'unix');
                    item.contract_date = formatDate(this.editedItem.contract_date, 'DD/MM/YYYY', 'YYYY-MM-DD');
                    item.contract_end_date = formatDate(this.editedItem.contract_end_date, 'DD/MM/YYYY', 'YYYY-MM-DD');
                    item.contract_validity_date = formatDate(this.editedItem.contract_validity_date, 'DD/MM/YYYY', 'YYYY-MM-DD');
                    item.contract_end_validity_date = formatDate(this.editedItem.contract_end_validity_date, 'DD/MM/YYYY', 'YYYY-MM-DD');

                    this.itemData = item;

                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData);
                }

            },

            resetForm() {

                this.documents = [];

                this.itemData = Object.assign({}, this.blankItemData);
                this.$refs[this.PREFIX + '_FORM'].reset();
            },

            onSubmit() {

                if(!this.isAnnex){
                    delete this.itemData.apply_at;
                }

                let formData = new FormData();
                formData = serialize(this.itemData, {}, formData);

                if (this.documents.length > 0) {
                    for(let i = 0; i< this.documents.length; i++){
                        formData.append('document[]', this.documents[i]);
                    }
                }

                if (this.action == 'adding' || this.isAnnex) {
                    let url = '/'+this.MODULE_PREFIX + (this.isAnnex? '_annex' : '');
                    this.async('post', url, formData, function (resp) {
                        this.$emit('item-added', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                        if(!this.isAnnex) {
                            this.$router.push({ name: 'contract_registry_details', params: {id: resp.data.item.id_contract} })
                        }

                    }, false,  {headers:{'Content-Type': 'multipart/form-data'}});

                } else {
                    this.async('put', '/'+this.MODULE_PREFIX + '/' + this.itemData.id_contract, formData, function (resp) {
                        this.$emit('item-edited', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    // this.$router.push({ name: 'contract_registry_details', params: {id: this.itemData.id_contract} })
                    }, false,  {headers:{'Content-Type': 'multipart/form-data'}});

                }
            },
        },

        created() {

            this.async('get', '/countries', {}, (res) => {this.countries = res.data});
            this.async('get', '/contractregistry_category', {params:{status:1}}, (res) => {this.contractCategories = res.data});
        }
    }
</script>