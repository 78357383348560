<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_agreement_register')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card no-body>

            <b-card-body>
                <b-row>

                    <b-col cols="12">
                        <b-row class="">
                            <b-col
                                    cols="12"
                                    md="3"
                                    class=" mb-1 date-range-col"
                            >
                                <label>{{ $t('label_date_agreement_conclusion') }}</label>

                                <div class="date-range-filter">
                                    <custom-date-picker
                                            class="date--picker no-label"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="filterData.contract_date"
                                            @input="filterData.contract_date = $event"
                                    >
                                        <template #clear-btn="{ vm }">
                                            <feather-icon icon="XIcon" size="14"/>
                                        </template>
                                    </custom-date-picker>

                                    <div class="date-separator"/>

                                    <custom-date-picker
                                            class="date--picker no-label"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="filterData.contract_date_to"
                                            @input="filterData.contract_date_to = $event"
                                    >
                                        <template #clear-btn="{ vm }">
                                            <feather-icon icon="XIcon" size="14"/>
                                        </template>
                                    </custom-date-picker>
                                </div>

                                <!--</b-form-group>-->
                            </b-col>
                            <b-col
                                    cols="12"
                                    md="3"
                                    class=" mb-1 date-range-col"
                            >
                                <label>{{ $t('label_agreement_expiration_date') }}</label>

                                <div class="date-range-filter">
                                    <custom-date-picker
                                            class="date--picker no-label"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="filterData.contract_end_date"
                                            @input="filterData.contract_end_date = $event"
                                    >
                                        <template #clear-btn="{ vm }">
                                            <feather-icon icon="XIcon" size="14"/>
                                        </template>
                                    </custom-date-picker>

                                    <div class="date-separator"/>

                                    <custom-date-picker
                                            class="date--picker no-label"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="filterData.contract_end_date_to"
                                            @input="filterData.contract_end_date_to = $event"
                                    >
                                        <template #clear-btn="{ vm }">
                                            <feather-icon icon="XIcon" size="14"/>
                                        </template>
                                    </custom-date-picker>
                                </div>

                            </b-col>

                            <b-col cols="12" md="3" class="mb-1">
                                <label>{{$t('label_category')}}</label>

                                <v-select
                                        v-model="filterData.id_contract_category"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="contractCategories"
                                        :reduce="val => val.id"
                                        label="idCountry"
                                        :clearable="true"
                                        :placeholder="$t('label_select')"
                                >
                                    <template v-slot:option="option">
                                        {{option.name}}
                                    </template>
                                    <template v-slot:selected-option="option">
                                        {{option.name}}
                                    </template>
                                    <template v-slot:no-options="option">
                                        {{$t('label_no_items')}}
                                    </template>
                                </v-select>

                            </b-col>


                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <b-card
                no-body
                class="mb-0"
        >

            <div class="m-2 mt-3">

                <b-row>

                    <b-col
                            cols="12"
                            md="2"
                            class="d-flex align-items-center justify-content-start  mb-md-0"

                    >
                        <!--<label>{{ $t('label_show') }}</label>-->
                        <v-select
                                v-model="tableData.perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                :searchable="false"
                                class="per-page-selector d-inline-block mr-1 mb-1"
                        >
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>

                        <column-chooser class="mb-1" v-model="selectedColumns" :not-touch="['id']"
                                        :columns="columnsDefs"></column-chooser>

                    </b-col>

                <b-col cols="12" md="5" >
                   <div class="d-flex align-items-center flex-wrap flex-md-nowrap">
                       <b-form-radio
                               id="archived-agreements"
                               class="mr-1 mb-1"
                               value="archived_agreements"

                               v-model="filterData.actual_agreements"
                               button
                               button-variant="outline-primary"


                       >
                           {{ $t('label_archived_agreements') }}
                       </b-form-radio>
                       <b-form-radio
                               id="actual-agreements"
                               value="actual_agreements"
                               class="mb-1"
                               v-model="filterData.actual_agreements"
                               button
                               button-variant="outline-primary"

                       >
                           {{ $t('label_actual_agreements') }}
                       </b-form-radio>
                   </div>
                </b-col>
                    <!-- Search -->
                    <b-col
                            cols="12"
                            md="5"
                            class="ml-auto flex-wrap flex-md-nowrap d-flex align-items-center justify-content-start mb-md-0"
                    >

                        <b-form-input
                                v-model="tableData.searchQuery"
                                type="search"
                                debounce="500"
                                class="d-inline-block mr-1 mb-1"
                                :placeholder="$t('label_search')+'...'"
                        />

                        <b-button
                                class="mb-1"
                                v-if="$can('edit', 'contractregistry.tab_details')"
                                @click="editedItem = false;$bvModal.show(PREFIX + '-modal')"
                                variant="primary"

                        >
                            <span class="text-nowrap">{{ $t('label_add_agreement') }}</span>
                        </b-button>

                    </b-col>
                </b-row>

            </div>

            <!--***********************************************************TABLE*********************************************-->

            <div class="px-2 table-container-wrap">
                <!--sticky-header-->
                <b-table

                        :ref="PREFIX+'_TABLE'"
                        class="position-relative transited-table"
                        :items="tableItems"
                        responsive
                        no-border-collapse
                        :busy="isBusy"
                        :fields="selectedColumns"
                        primary-key="id"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"
                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                    </template>


                    <template #cell(contract_category_name)="data">
                        {{data.item.category.name}}
                    </template>

                    <template #cell(contract_duration)="data">
                        {{formatDate(data.item.contract_date, 'DD.MM.YYYY', 'YYYY-MM-DD HH:mm:ss') + (data.item.contract_end_date? '-' + formatDate(data.item.contract_end_date, 'DD.MM.YYYY', 'YYYY-MM-DD HH:mm:ss') : '')}}
                    </template>

                    <template #cell(contract_subject)="data">
                        <router-link v-if="$can('view', 'contractregistry.tab_details')" :to="{ name: 'contract_registry_details', params: { id: data.item.id_contract } }"
                        >
                            {{data.item.contract_subject }}
                        </router-link>
                        <template v-else>
                            {{data.item.contract_subject }}
                        </template>
                    </template>


                    <template #cell(actions)="data">

                        <b-dropdown
                                dropleft
                                boundary="window"
                                variant="link"
                                no-caret
                                @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
                                @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
                                :right="$store.state.appConfig.isRTL"
                        >

                            <template #button-content>
                                <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                />
                            </template>

                            <b-dropdown-item
                                    @click="editedItem = data.item; isAnnex = false; $bvModal.show(PREFIX + '-modal');">
                                <feather-icon icon="EditIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                                    @click="editedItem = data.item;$bvModal.show('task-modal')">
                                <feather-icon icon="CheckSquareIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_add_task') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                                    @click="isAnnex = true; $bvModal.show(PREFIX + '-modal');">
                                <feather-icon icon="AlertOctagonIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_add_annex') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="deleteItem(data.item.id_contract)">
                                <feather-icon icon="TrashIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>

                </b-table>
                <div class="mx-2 mb-2">
                    <b-row>

                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >

                            <b-pagination
                                    v-model="tableData.currentPage"
                                    :total-rows="tableTotal"
                                    :per-page="tableData.perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>

        </b-card>
        <!--:module-item="false"-->
        <process-item-modal
                :is-annex="isAnnex"
                @item-added="refreshDataTable()"
                @item-edited="refreshDataTable()"
                :edited-item="editedItem"
        ></process-item-modal>

        <process-task-modal
                task-type="contract_registry"
                :module-item="editedItem"
        ></process-task-modal>


    </div>
</template>

<script>

    import {
        BCard, BButton, BTable, BMedia, BAvatar, BLink, BFormInput,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip, BFormRadio

    } from 'bootstrap-vue'

    import vSelect from 'vue-select'

    import processTaskModal from './../agreement/modals/processTaskModal'
    import columnChooser from '@/views/components/columnChooser'
    import processItemModal from './modal/processItemModal'
    import infinityScroll from '@/views/components/infinityScroll'

    import {CONTRACT_REG_PREFIX as MODULE_PREFIX, CONTRACT_REG_PREFIX as PREFIX} from './moduleHelper'

    export default {
        components: {
            BCard,
            BCardBody,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BFormCheckbox,
            BCardHeader,
            BTooltip,
            BFormInput,
            BFormRadio,
            columnChooser,
            vSelect,
            infinityScroll,
            processItemModal,
            processTaskModal,
            // annexModal
        },
        props: ['tableType'],
        data() {
            return {
                PREFIX,
                MODULE_PREFIX,

                editedItem: false,
                selectedColumns: [],
                // editedTask: false,

                columnsDefs: [

                    {label: 'label_agreement_subject', key: 'contract_subject', sortable: false},
                    {label: 'label_category', key: 'contract_category_name', sortable: false},
                    {label: 'label_date_agreement_conclusion', key: 'contract_add_date', sortable: false, formatter: (v) => {
                            return v ? formatDate(v, 'DD.MM.YYYY', 'YYYY-MM-DD HH:mm:ss') : '--'
                        }},

                    {label: 'label_contract_duration', key: 'contract_duration', sortable: false},

                    {label: 'label_action', key: 'actions'}
                ],
                isBusy: false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 100,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },
                filterData: {
                    contract_date: '',
                    contract_date_to: '',
                    contract_end_date: '',
                    contract_end_date_to: '',
                    id_contract_category: '',
                    actual_agreements: 'actual_agreements',
                    // archived_agreements:null,
                },
                contractCategories:[],
                isAnnex: false,
            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        created() {

            this.getPageData();

            this.columnsDefs = this.columnsDefs.filter((el) => {
                if (el.key == 'actions' && !this.$can('edit', 'contractregistry.tab_details')) {
                    return false;
                }
                return true;
            });

            this.refreshDataTable();
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },

        methods: {

            getPageData() {

                this.async('get', '/contractregistry_category', {params:{status:1}}, (res) => {this.contractCategories = res.data});

            },

            refreshDataTable: function () {
                this.isBusy = true;

                let params =  {

                    length: this.tableData.perPage,
                    start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                    search: this.tableData.searchQuery,
                    sort_by: this.tableData.sortBy,
                    sort_desc: this.tableData.sortDesc? true : null,

                    contract_date: this.filterData.contract_date,
                    contract_date_to: this.filterData.contract_date_to,
                    contract_end_date: this.filterData.contract_end_date,
                    contract_end_date_to: this.filterData.contract_end_date_to,
                    id_contract_category: this.filterData.id_contract_category,

                };

                if(this.filterData.actual_agreements == 'actual_agreements'){
                    params.actual_agreements = 1;
                } else {
                    params.archived_agreements = 1;
                }

                this.async('get', this.MODULE_PREFIX, {
                    params: params

                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;

                    this.isBusy = false;
                });

            },
            deleteItem(id) {
                this.confirmDeleting((result) => {

                        this.async('delete', '/' + this.MODULE_PREFIX + '/' + id, {}, function (resp) {
                            this.refreshDataTable();
                        });

                })
            },

        },

    }
</script>

